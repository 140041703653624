* {
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
    background-color: #f6f6f6;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

.header {
    width: 100%;
    height: 70px;
    float: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 600px){
    .header {
        height: 48px;
    }
}

.header_menu {
    font-size: 18px;
    color: white;
    float: left;
    background: transparent;
    border: none;
    padding-top: 6px;
}

@media screen and (max-width: 600px){
    .header_menu {
        font-size: 12px;
        padding-top: 0px;
    }

}


.header_menu span {
    color: white;
    float: left;
    background: transparent;
    border: none;
}

.logo {
    width: 80px;
    height: 60px;
    float: left;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 5px;
}

@media screen and (max-width: 600px){
    .logo {
        width: 40px;
        height: 30px;
        margin-left: 2vw;
        margin-right: 0vw;
        margin-top: 8px;
    }
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 0;
    margin: 0;
}

.icons img{
    height: 100px;
    padding: 0;
    margin: 0;
}
.us_icons {
    height: 250px;
    width: 1000px;
    padding: 0;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px){
    .us_icons {
        display: none;
    }
}

.gift_div {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.gift_div:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px){
    .gift_div {
        display: none;
    }
}

.gift {
    height: 70px;
    width: 70px;
}

.header_image {
    height: 70vh;
    width: 100%;
    background-image: url(./logo/bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .header_image {
        height: 30vh;
    }   
}

.header_image_one {
    height: 70vh;
    width: 100%;
    background-image: url(./logo/1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .header_image_one {
        height: 30vh;
    }   
}

.header_image_two {
    height: 70vh;
    width: 100%;
    background-image: url(./logo/2.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .header_image_two {
        height: 30vh;
    }   
}

.header_image_three {
    height: 70vh;
    width: 100%;
    background-image: url(./logo/3.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .header_image_three {
        height: 30vh;
    }   
}

.header_image_four {
    height: 70vh;
    width: 100%;
    background-image: url(./logo/4.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .header_image_four {
        height: 30vh;
    }   
}

.course_title_en {
    font-size: 36px;
    margin-bottom: -38px;
    font-weight: 300;
    letter-spacing: 1px;
    word-spacing: 3px;
    color: rgba(0, 0, 0, 0.1);
}

.course_title_ch {
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 40px;
    color: rgba(0, 0, 0, 0.8);
}

.course_title_sub {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.3px;
    margin-top: -10px;
    color: rgba(0, 0, 0, 0.5);
}

.jr-program-course {
    margin-top: 24px;
    text-align: left;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.jr-program-course a {
    text-decoration: none;
    display: block;
}

.jr-program-course_card {
    position: relative;
    max-width: 520px;
    border-radius: 0 10px 10px 0;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 14px 24px 14px 24px;
    margin: 30px auto 0 auto;
}

.jr-program-course_level {
    display: flex;
    justify-content: space-between;
}

.jr-program-course_block {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 6px;
    margin-right: 2px;
    margin-bottom: 2px;
}

.jr-program-course_block_left {
    border-radius: 4.5px 0 0 4.5px;
    background-color: #084891;
}

.jr-program-course_block_center {
    background-color: #b6d6fa;
}

.jr-program-course_block_center_fill {
    background-color: #084891;
}

.jr-program-course_block_right {
    background-color: #b6d6fa;
    border-radius: 0 4.5px 4.5px 0;
}

.jr-program-course_block_right_fill {
    background-color: #084891;
    border-radius: 0 4.5px 4.5px 0;
}

.jr-program-course_block_text {
    margin-left: 6px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: black;
}

.jr-program-course_title {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
}

.jr-program-course_description {
    margin-top: 5px;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.6px;
    color: rgba(0, 0, 0, 0.9);
}

.jr-program-course_coverage {
    margin-bottom: 0;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: rgba(0, 0, 0, 0.85);
}

.jr-course-fullstack {
    position: relative;
}

.jr-program-course_card::after {
    content: "";
    position: absolute;
    left: -6px;
    top: 0;
    width: 6px;
    height: 100%;
    background-color: #084891;
    border-radius: 10px 0 0 10px;
}

.profile-image {
    border: 2px solid #eb2f96;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}

.footer{
     /* background-color: #00274b; */
     font-size: 14px;
}

.footer h4{
    font-size: 14px;
    letter-spacing: 0.6px;
    text-align: left;
}

.footer p{
    display: block;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #979797;
    margin-bottom: 1px;
    text-align: left;
}

.header_content{
    letter-spacing: 0.6px;
    font-weight: 900;
    font-size: 3em;
    color: #fff;
    text-align: left;
    margin-top: 30vh;
    margin-left: 5vw;
}

@media screen and (max-width: 600px) {
    .header_content{
        font-size: 1.5em;
        margin-top: 3vh;
        margin-left: 5vw;
    }
}

.courseDetails {
    padding: 0;
    margin: 6px 0;
    font-size: 20px;
    text-align: left;
    
}

.course {
    padding-top: 20px;    
}


.courseDetails h3 {
    font-size: 20px;
    color: #03A9f4;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;
}

.courseDetails img {
    float: left;
}

.courseDetails p {
    font-size: 16px;
    margin: 5px;
    
}

.courseDetailsImg {
    float: left;
    margin: 5px;
}

.courseDetailsDescription {
    float: right;
    margin: 5px;
    width: 100%;
}

.tag {
    font-size: 13px;
    font-weight: 700;
    color: #0072a7;
    line-height: 30px;
    height: 30px;
    padding: 0px 12px;
    background: #c9eeff;
    border-radius: 12px;
    margin: 6px 6px 0 0;
    float: left;
    text-align: left;
}

.courseDetailsImg p{
    font-size: 20px;
   
}

.courseDetailsImg div{
    float: left;
}


.courseDetailsImg img{
    width: 60px;
    height: 60px;
}

.courseDescription {
    padding: 10px;
    margin: 6px 0;
    font-size: 18px;
    text-align: left;
}

.courseSteps {
    display: block;
}

.scan {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    display: block;
}

.course_img {
    height: 20vh;
    width:  80%;
}

.questions {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 20px;
}

@media screen and (max-width: 600px){
    .questions {
        font-size: 17px;
    }
}

.questions_header{
    font-size: 25px;
    text-align: left;
}

@media screen and (max-width: 600px){
    .questions_header {
        font-size: 15px;
    }
}

.questions p{
    font-size: 20px;
    text-align: left;
    margin: 10px;

}

@media screen and (max-width: 600px){
    .questions p{
        font-size: 13px;
    }
}
